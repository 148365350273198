import React from 'react';
import { messages } from './messages';
import { KyruusFormattedMessage } from '@kyruus/intl';
import {
  ClearAllFiltersLink,
  SearchSummaryText
} from './search-summary-styles';

function getSearchDescription(searchSummary) {
  return searchSummary.search_description ? (
    <span data-testid="search-results-description">
      &quot;{searchSummary.search_description}&quot;
    </span>
  ) : null;
}

function logClearFiltersThunk(log) {
  return () => {
    log('user_action.search_results.clear_filters', { facet_cleared: 'all' });
  };
}

function getClearFiltersSearch(getUpdatedSearch) {
  return getUpdatedSearch([
    { action: 'delete_key', key: 'display_location' },
    { action: 'delete_key', key: 'location' },
    { action: 'delete_key', key: 'distance' },
    { action: 'delete_key', key: 'filter' }
  ]);
}

function SearchSummary({
  totalProviders,
  searchSummary,
  getUpdatedSearch,
  locationName,
  hideSearchDescription, // description is hidden when rendering in location page provider drawer
  log,
  showClearLink = true,
  isLoadingOverlayShown = false
}) {
  let searchSummaryMessageDescriptor;
  let searchSummaryMessageTestId;
  const searchDescription = getSearchDescription(searchSummary);
  const showSearchDescription =
    searchDescription != null && hideSearchDescription !== true;

  const mapTypeSearch = locationName != null;
  if (mapTypeSearch === true) {
    if (showSearchDescription) {
      searchSummaryMessageDescriptor = messages.foundfor;
      searchSummaryMessageTestId = 'messages-foundfor';
    } else {
      searchSummaryMessageDescriptor = messages.found;
      searchSummaryMessageTestId = 'messages-found';
    }
  } else {
    if (showSearchDescription && searchSummary.location != null) {
      searchSummaryMessageDescriptor = messages.foundfornear;
      searchSummaryMessageTestId = 'messages-foundfornear';
    } else if (showSearchDescription) {
      searchSummaryMessageDescriptor = messages.foundfor;
      searchSummaryMessageTestId = 'messages-foundfor';
    } else if (searchSummary.location != null) {
      searchSummaryMessageDescriptor = messages.foundnear;
      searchSummaryMessageTestId = 'messages-foundnear';
    } else {
      searchSummaryMessageDescriptor = messages.found;
      searchSummaryMessageTestId = 'messages-found';
    }
  }

  const searchSummaryText = (
    <KyruusFormattedMessage
      {...searchSummaryMessageDescriptor}
      values={{
        location: searchSummary.display_location || searchSummary.location,
        providerCount: totalProviders,
        searchTerm: searchDescription
      }}
    />
  );

  let clearLink = null;
  if (
    searchSummary.has_filters ||
    searchSummary.filter?.length ||
    searchSummary.location
  ) {
    // search V8 - searchSummary has 'has_filters' (boolean) key to determine whether filters are applied
    // search V9 - searchSummary has 'filter' (array) key to determine whether filters are applied
    const logClearFilters = logClearFiltersThunk(log);
    const clearFiltersSearch = getClearFiltersSearch(getUpdatedSearch);
    clearLink = (
      <ClearAllFiltersLink
        to={clearFiltersSearch}
        id="clear-filters"
        className="clear-filters"
        onClick={logClearFilters}
      >
        <KyruusFormattedMessage {...messages.clearfilters} />
      </ClearAllFiltersLink>
    );
  }

  return (
    <>
      <SearchSummaryText className="d-inline-block hidden-print">
        <span data-testid="search-summary-text">
          <span data-testid={searchSummaryMessageTestId} aria-live="polite">
            {!isLoadingOverlayShown && searchSummaryText}
          </span>{' '}
          {locationName && (
            <span aria-live="polite">
              {!isLoadingOverlayShown && (
                <KyruusFormattedMessage
                  {...messages.locationName}
                  values={{
                    location: locationName
                  }}
                />
              )}
            </span>
          )}
        </span>
      </SearchSummaryText>
      {showClearLink && clearLink}
    </>
  );
}

export default SearchSummary;
